/*
|--------------------
|     PAGE HOME
|--------------------
*/

.page-template-home {
    #block-banner-home {
        background-color: $white-alt;
        position: relative;
        min-height: 800px;
        @include media-breakpoint-down(lg) {
            height: inherit;
            min-height: inherit;
            padding: 180px 0 100px 0;
            text-align: center;
        }
        .anim-intro {
            overflow: hidden;
            z-index: 7;
            height: 510px;
            padding-top: 52px;
            width: 100%;
            @include media-breakpoint-down(lg) {
                height: 433px;
            }
            @include media-breakpoint-down(md) {
                height: 320px;
            }
            .swiper-slide {
                .bg {
                    height: 400px;
                    width: 400px;
                    border-radius: 60px;
                    display: block;
                    background-position: center;
                    background-size: cover;
                    left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    transform: rotate(-60deg);
                    transition: all ease 0.8s;
                    @include media-breakpoint-down(xl) {
                        height: 340px;
                        width: 340px;
                    }
                    @include media-breakpoint-down(lg) {
                        height: 340px;
                        width: 340px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 200px;
                        width: 200px;
                        border-radius: 20px;
                    }
                }
                &.swiper-slide-prev {
                    .bg {
                        transform: rotate(-30deg);
                        transition: all ease 0.8s;
                    }
                }
                &.swiper-slide-active {
                    .bg {
                        transform: rotate(0deg);
                        transition: all ease 0.8s;
                    }
                }
                &.swiper-slide-next {
                    .bg {
                        transform: rotate(30deg);
                        transition: all ease 0.8s;
                    }
                }
            }
        }
    }
    .bar-vertical {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -60px;
        margin: auto;
        height: 120px;
        width: 4px;
        display: block;
        z-index: 1;
        background-color: $orange;
    }
}
