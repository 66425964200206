/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| DECLARATION
|----------------
|
*/

$very-dark-grey: #141414;
$dark-grey: #424242;
$light-grey: #dedede;
$very-light-grey: #fafafa;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$grey: #ebf2fa;
$orange: #ea9010;
$green: #134c41;
$green-alt: #93c01f;
$background: #ebf2fa;
$white-alt: #f8f9ff;
$white: #ffffff;
$black: #000000;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

$grey: $grey;
$orange: $orange;
$green: $green;
$green-alt: $green-alt;
$background: $background;
$white-alt: $white-alt;
$white: $white;
$black: $black;
