/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
    @if is-enabled("background-images") {
        @extend .bg-cover;
    }

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-block: var(--header-height);
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: block;
    }

    img {
        will-change: transform;
        transition: transform 2s $easeOutExpo;
    }

    /*
    * Hover state
    */
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: var(--header-height);
}

/*
|
| CMS
|------
*/
.cms {
    p,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    h2 {
        font-family: "Gilroy-Bold";
        font-size: 38px;
        line-height: 50px;
        color: #134c41;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    ul,
    ol {
        padding-left: 1.25em;

        li {
            &:not(:first-child) {
                margin-top: 0.25em;
            }
        }
    }

    ul {
        li {
            list-style: disc;

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        li {
            list-style: decimal;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;

            background-image: url("../img/icon/quote-left.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md) {
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}
