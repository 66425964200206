/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    /*
    |
    | Section contact
    |------------------
    */
    .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: $very-dark-grey;
        color: $white;
        text-align: center;

        .item-title {
            @if is-enabled("background-images") {
                @extend .title-xxl;
            }

            font-size: 8vw;
            font-weight: 300;
            line-height: 1;
        }
        a {
            text-decoration: underline;
        }
    }
}
