#footer {
    background-color: $green;
    color: white;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        text-align: center;
    }
    h2 {
        font-family: "Gilroy-Bold";
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
    }
    .btn {
        @include media-breakpoint-down(sm) {
            padding: 10px 10px 10px 10px;
            font-size: 17px;
        }
    }
    .elem {
        position: relative;
        &:last-child {
            &:before {
                content: "";
                width: 1px;
                height: 100%;
                display: block;
                position: absolute;
                left: -30px;
                background-color: white;
            }
        }
        ul {
            li {
                display: block;
                a {
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            &.link-groupe {
                li {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .copy {
        font-family: "Gilroy-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.9;
        border-top: 1px solid white;
        .block-copy {
            display: flex;
            @include media-breakpoint-down(sm) {
                display: grid;
                padding-bottom: 15px;
            }
            .date {
                float: left;
                padding: 20px 0 35px 0;
                @include media-breakpoint-down(sm) {
                    padding: 10px 0 0 0;
                }
            }
            .createdby {
                float: right;
                padding: 20px 0 35px 0;
                @include media-breakpoint-down(sm) {
                    padding: 10px 0 0 0;
                }
            }
            ul {
                margin: auto;
                display: table;
                text-align: center;
                li {
                    font-family: "Gilroy-Medium";
                    padding: 20px 0 35px 0;
                    position: relative;
                    display: inline-block;
                    @include media-breakpoint-down(sm) {
                        padding: 10px 0 0 0;
                    }
                    &:not(:last-child) {
                        &:after {
                            content: "";
                            height: 3px;
                            width: 3px;
                            background-color: white;
                            border-radius: 50%;
                            margin: 0 10px 1px 10px;
                        }
                    }
                }
            }
        }
    }
}
