#block-valeurs {
    position: relative;
    .values {
        .item {
            text-align: center;
            color: $white;
            .block-image {
                max-width: 88px;
                height: 70px;
                margin: auto;
                img {
                    max-height: 70px;
                    margin: auto;
                }
            }
            h3 {
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 36px;
            }
            .text {
                font-family: "Gilroy-Medium";
                font-size: 18px;
                line-height: 28px;
                color: #ffffff;
            }
        }
    }
    .wavesvg {
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -1px;
        z-index: 8;
        background-color: $white;
        transform: scaleY(-1);
        path {
            stroke: none;
            fill: $green;
        }
    }
}

#block-banner-image {
    background-color: #f8f9ff;
    position: relative;
    min-height: calc(100vh - 100px);
    background-size: cover;
    background-position: center;
    overflow: hidden;
    color: white;
    @include media-breakpoint-down(lg) {
        height: 100%;
        padding: 164px 15px 30px 15px;
    }
    h1 {
        color: white;
    }
}

#block-banner-white {
    background-color: #fff;
    position: relative;
    height: 100vh;
    min-height: 700px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    color: white;
    @include media-breakpoint-down(md) {
        padding: 175px 0 50px 0;
        min-height: initial;
        height: initial;
    }
    h1 {
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }
    .bg-img-l,
    .bg-img-r {
        height: 400px;
        width: 400px;
        border-radius: 60px;
        position: absolute;
        background-size: cover;
        background-position: center;
        margin: auto;
        left: 0;
        right: 0;
        z-index: 5;
        transform: rotate(0deg);
        @include media-breakpoint-down(md) {
            height: 300px;
            width: 300px;
        }
        @include media-breakpoint-down(sm) {
            height: 270px;
            width: 270px;
        }
    }
    .bg-img-l {
        /*
        left: -170px;
        transform: rotate(-12.69deg);*/
        @include media-breakpoint-down(md) {
            transform: rotate(-28deg);
            left: 0;
            right: 0;
            margin: 0 auto;
            position: relative;
        }
    }
    .bg-img-r {
        /*
        right: -170px;
        transform: rotate(12.6903deg);*/
        @include media-breakpoint-down(md) {
            left: 0;
            right: 0;
            margin: 0 auto;
            position: absolute;
        }
    }
    .text {
        color: #666680;
    }
}

.bar-vertical-white {
    position: absolute;
    left: 0;
    right: 0;
    margin: -60px auto 0 auto;
    height: 120px;
    width: 4px;
    display: block;
    z-index: 1;
    background-color: $orange;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.filter {
    opacity: 0.5;
    position: absolute;
    background-color: #000;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.block-image {
    &.fl-logo {
        img {
            max-width: 330px;
            @include media-breakpoint-down(sm) {
                max-width: 250px;
            }
        }
    }
    &.fl-image {
        img {
            max-width: 100%;
            border-radius: 15px;
        }
    }
    .bg {
        width: 100%;
        height: 700px;
        border-radius: 15px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(xl) {
            height: 700px;
        }
        @include media-breakpoint-down(lg) {
            height: 500px;
        }
        @include media-breakpoint-down(md) {
            height: 400px;
        }
        @include media-breakpoint-down(sm) {
            height: 300px;
        }
    }
}

#block-cta-footer {
    .block {
        min-height: 450px;
        background: #ffffff;
        box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.1);
        border-radius: 24px;
        padding: 30px;
        transition: all ease 0.3s;
        @include media-breakpoint-down(xl) {
            min-height: 500px;
        }
        @include media-breakpoint-down(lg) {
            min-height: inherit;
        }

        h3 {
            font-family: "Gilroy-medium";
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: initial;
            color: $green;
            min-height: 100px;
            @include media-breakpoint-down(xl) {
                min-height: 130px;
            }

            @include media-breakpoint-down(lg) {
                min-height: initial;
            }
            @include media-breakpoint-down(md) {
                font-size: 32px;
                line-height: 36px;
            }
        }
        .text {
            font-family: "Gilroy-medium";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            min-height: 180px;
            @include media-breakpoint-down(lg) {
                min-height: initial;
            }
        }
        .btn {
            left: 30px;
            bottom: 30px;
            position: absolute;
            @include media-breakpoint-down(lg) {
                position: relative;
                bottom: inherit;
                left: inherit;
            }
            &:hover {
                color: $green;
                background: $white;
            }
        }
        &:hover {
            background: #eea63f;
            transition: all ease 0.3s;
        }
    }
}

#block-text-media {
    overflow: hidden;
    min-height: 600px;
    @include media-breakpoint-up(lg) {
        display: flex !important;
        vertical-align: middle;
        align-items: center;
    }
    .block-media {
        position: absolute;
        width: 100%;
        .gallery {
            border-radius: 20px;
            -moz-border-radius: 20px;
            -webkit-border-radius: 20px;
            .bg {
                height: 600px;
                width: 100%;
                display: block;
                background-position: center;
                background-size: cover;
            }
            .control-right {
                left: initial !important;
                right: -40px;
            }
        }
    }
    &.inverse {
        .block-media {
            left: -30px;
        }
    }

    .swiper-media {
        overflow: hidden;
        position: relative;
        border-radius: 15px;
        -moz-border-radius: 15px;
        -webkit-border-radius: 15px;
        .item {
            margin: 0;
            .bg {
                width: 100%;
                height: 600px;
                display: block;
                background-position: center;
                background-size: cover;
                @include media-breakpoint-down(md) {
                    height: 380px;
                }
            }
            .legend {
                position: absolute;
                bottom: -60px;
            }
        }

        .slider__controls {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 12;
            transform: rotate(90deg);
            @include media-breakpoint-down(md) {
                left: -20px;
            }
            @include media-breakpoint-down(sm) {
                left: -50px;
            }

            .slider__pagination {
                text-align: center;

                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    background: #fff;
                    opacity: 1;
                    margin: 0 5px;
                    -webkit-border-radius: 20px;
                    border-radius: 20px;
                    -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
                    -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
                    transition: opacity 0.5s, background-color 0.5s, width 0.5s;
                    -webkit-transition-delay: 0.5s, 0.5s, 0s;
                    -o-transition-delay: 0.5s, 0.5s, 0s;
                    transition-delay: 0.5s, 0.5s, 0s;
                    color: #000 !important;
                    opacity: 1 !important;
                }
            }

            .swiper-pagination-clickable .swiper-pagination-bullet {
                cursor: pointer;
            }

            .slider__pagination .swiper-pagination-bullet-active {
                opacity: 1;
                background: #ffffff;
                width: 100px;
                -webkit-transition-delay: 0s;
                -o-transition-delay: 0s;
                transition-delay: 0s;
            }
        }
    }
    img {
        width: 100%;
        @include media-breakpoint-down(lg) {
            border-radius: 15px;
        }
    }
}

.block-text-media-home {
    overflow: hidden;
    min-height: 480px;
    @include media-breakpoint-up(lg) {
        display: flex !important;
        vertical-align: middle;
        align-items: center;
    }
    h2 {
        font-family: "Gilroy-Bold";
        font-size: 24px;
        line-height: 32px;
        color: #000;
    }
    .block-media {
        position: absolute;
        width: 100%;
        .bg {
            height: 400px;
            width: 100%;
            border-radius: 15px;
            display: block;
            background-position: center;
            background-size: cover;
        }
    }
    &.inverse {
        .block-media {
            .bg {
                border-radius: 18px;
            }
        }
    }
    img {
        width: 100%;
        @include media-breakpoint-down(lg) {
            border-radius: 15px;
        }
    }
}

#block-text-list {
    overflow: hidden;
    min-height: 600px;
    @include media-breakpoint-up(lg) {
        display: flex !important;
        vertical-align: middle;
        align-items: center;
    }
    .block-media {
        position: absolute;
        width: 100%;
        .bg {
            height: 100%;
            width: 100%;
            border-radius: 15px 0 0 15px;
            display: block;
            background-position: center;
            background-size: cover;
        }
    }
    &.inverse {
        .block-media {
            left: -30px;
            .bg {
                border-radius: 0 15px 15px 0;
            }
        }
    }
    img {
        width: 100%;
        @include media-breakpoint-down(lg) {
            border-radius: 15px;
        }
    }
    .block-text {
        li {
            position: relative;
            padding-bottom: 10px;
            &:before {
                content: "";
                opacity: 0;
                background-color: #ea9010;
                height: 100%;
                width: 3px;
                display: block;
                left: -20px;
                position: absolute;
                border-radius: 50px;
                transition: all ease 0.3s;
            }
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: -8px;
                left: 0;
                background-color: #c6cde8;
            }
            .title {
                display: block;
                font-family: "Gilroy-Bold";
                font-size: 40px;
                line-height: 48px;
                color: $green;
            }
            &.active,
            &:hover {
                &:before {
                    transition: all ease 0.3s;
                    opacity: 1;
                }
            }
        }
    }
}

#block-image-list {
    overflow: hidden;
    min-height: 600px;
    color: white;
    background-color: $green;
    @include media-breakpoint-up(lg) {
        display: flex !important;
        vertical-align: middle;
        align-items: center;
    }
    .wavesvg {
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -20px;
        z-index: 8;
        background-color: $white;
        path {
            stroke: none;
            fill: $green;
        }
    }
    h2,
    h3 {
        color: white;
    }
    .block-img-list {
        display: contents;
        .img-list {
            width: 100%;
            height: 596px;
            border-radius: 50px;
            display: block;
            background-position: center;
            background-size: cover;
            &.active {
                display: block;
                transition: all ease 0.3s;
            }
        }
    }

    .bg {
        width: 100%;
        height: 250px;
        border-radius: 20px;
        background-position: center;
        background-size: cover;
    }
    .block-text {
        .elem-list {
            cursor: pointer;
            position: relative;
            &:not(:last-child) {
                padding-bottom: 10px;
            }
            &:before {
                content: "";
                opacity: 0;
                background-color: $orange;
                height: 100%;
                width: 3px;
                display: block;
                left: -20px;
                position: absolute;
                border-radius: 50px;
                transition: all ease 0.3s;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
            .title {
                display: block;
                font-family: "Gilroy-Bold";
                font-size: 40px;
                line-height: 48px;
                color: $white;
            }
            .text {
                font-family: "Gilroy-Medium";
                font-size: 18px;
                line-height: 28px;
                opacity: 0.7;
            }
            &.active {
                .title {
                    color: $orange;
                    @include media-breakpoint-down(xl) {
                        color: white;
                    }
                }
                &:before {
                    opacity: 1;
                    transition: all ease 0.3s;
                }
            }
            &.hover {
                &:before {
                    opacity: 1;
                    transition: all ease 0.3s;
                }
            }
        }
    }
}

#block-chiffres {
    .item {
        padding: 0 30px;
        h2 {
            font-family: "Gilroy-Bold";
            font-size: 95px;
            line-height: 110px;
            display: block;
            align-items: center;
            text-align: center;
            color: $orange;
            @include media-breakpoint-down(sm) {
                font-size: 80px;
                line-height: 80px;
            }
        }
        .text {
            color: $green;
            font-family: "Gilroy-SemiBold";
            font-size: 20px;
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

#block-text-link-mea {
    background-color: $green;
    color: white;
    @include media-breakpoint-down(md) {
        min-height: initial;
    }
    .mh {
        min-height: 620px;
        @include media-breakpoint-down(md) {
            min-height: initial;
        }
    }
    h2 {
        color: white;
    }
    .elem {
        &.odd {
            margin-top: 15px;
            @include media-breakpoint-down(lg) {
                margin-top: initial;
            }
        }
        &.even {
            margin-top: -20px;
            @include media-breakpoint-down(lg) {
                margin-top: initial;
            }
        }
        .item {
            min-height: 112px;
            background: #ffffff;
            border-radius: 12px;
            display: block;
            color: $green;
            padding: 12px;
            position: relative;
            @include media-breakpoint-down(xl) {
                min-height: 142px;
            }
            @include media-breakpoint-down(lg) {
                min-height: 112px;
            }
            &.link {
                cursor: pointer;
                &:before {
                    content: "";
                    width: 24px;
                    height: 25px;
                    position: absolute;
                    bottom: 16px;
                    right: 13px;
                    transition: all ease 0.3s;
                    background-image: url("../img/icon/link.svg");
                }
                &:hover {
                    transition: all ease 0.3s;
                    box-shadow: 3px 5px 21px rgb(41 41 41);
                    &:before {
                        content: "";
                        transition: all ease 0.3s;
                        transform: rotate(90deg);
                    }
                }
            }
            .block {
                display: block;
                img {
                    float: left;
                    margin-right: 14px;
                }
                .detail {
                    float: left;
                    display: block;
                    h3 {
                        font-family: "Gilroy-SemiBold";
                        font-size: 18px;
                        line-height: 22px;
                        hyphens: auto;
                    }
                    .text {
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        line-height: 18px;
                        align-items: center;
                        color: #42526b;
                        padding-right: 30px;
                        display: block;
                    }
                }
            }
        }
    }
}

#block-text-image {
    background-color: $green;
    color: white;
    background-size: cover;
    background-position: center;
    min-height: 620px;
    position: relative;
    @include media-breakpoint-down(md) {
        min-height: 440px;
    }
    &:before {
        content: "";
        opacity: 0.65;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
    }
    .mh {
        min-height: 620px;
        @include media-breakpoint-down(md) {
            min-height: initial;
        }
    }
    h2 {
        color: white;
    }
    .wavesvg {
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: -1px;
        z-index: 1;
        fill: transparent;
        path {
            stroke: none;
            fill: #fff;
        }
    }
}

#block-text-green {
    position: relative;
    padding: 100px 15px;
    .elem {
        width: 100%;
        border-radius: 15px;
        background-color: $green;
        color: white;
        h2 {
            color: white;
            transition: all ease 0.3s;
            span {
                transition: all ease 0.3s;
            }
        }
        .link {
            font-family: "Gilroy-SemiBold";
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #ffffff;
            position: relative;
            transition: all ease 0.3s;
            &::before {
                content: "";
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: -8px;
                background-color: #fff;
                transition: all ease 0.3s;
            }
            &:hover {
                &::before {
                    content: "";
                    transition: all ease 0.3s;
                    background-color: $orange;
                }
            }
        }
        &:hover {
            h2 {
                span {
                    color: $orange;
                    transition: all ease 0.3s;
                }
            }
        }
    }
}

#block-mea {
    position: relative;
    &:before {
        content: "";
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 240px;
        width: 100%;
    }
    &.backgroundblue {
        background: #ebf2fa;
    }
    .item {
        background: #ffffff;
        box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.1);
        border-radius: 15px;
        .bg {
            width: 100%;
            height: 312px;
            display: block;
            background-position: center;
            background-size: cover;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        .detail {
            padding: 30px;
            h3 {
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 26px;
                color: $green;
            }
        }
    }
}

#block-6-mea {
    position: relative;
    background-color: $green;
    &:before {
        content: "";
        background-color: $white-alt;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        width: 100%;
    }
    h2 {
        color: white;
    }
    .text {
        color: white;
    }
    .item {
        background: #ffffff;
        box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.1);
        border-radius: 15px;
        .bg {
            width: 100%;
            height: 312px;
            display: block;
            background-position: center;
            background-size: cover;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        .detail {
            padding: 30px;
            h3 {
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 26px;
                color: $green;
            }
            .text {
                color: #666680;
            }
        }
    }
    .wavesvg {
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -62px;
        z-index: 0;
        background-color: $white;
        transform: scaleY(-1);
        path {
            stroke: none;
            fill: $green;
        }
    }
}

#block-society {
    overflow: hidden;
    position: relative;
    .item {
        background: #ffffff;
        box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.1);
        border-radius: 15px;
        height: 500px;
        @include media-breakpoint-down(xl) {
            height: 590px;
        }
        @include media-breakpoint-down(lg) {
            height: initial;
        }
        .bg {
            width: calc(100% - 60px);
            height: 150px;
            display: block;
            margin: 30px;
            background-position: center;
            background-size: cover;
            border-radius: 15px;
            @include media-breakpoint-down(sm) {
                margin: 15px;
                width: calc(100% - 30px);
            }
        }
        .detail {
            padding: 0px 30px 30px 30px;
            @include media-breakpoint-down(sm) {
                padding: 0px 15px 15px 15px;
            }
            h3 {
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 26px;
                color: $green;
            }
            .text {
                color: #666680;
            }
            .btn {
                position: absolute;
                bottom: 30px;
                @include media-breakpoint-down(lg) {
                    position: initial;
                    bottom: initial;
                }
            }
        }
    }
}

#block-history {
    overflow: hidden;
    position: relative;

    .block-progressbar {
        background: #d9e2e1;
        height: 5px;
        width: 100%;
        left: 0;
        position: relative;
        overflow: hidden;
        .progressbar {
            width: 6%;
            height: 5px;
            top: 0;
            left: 0;
            position: absolute;
            background-color: $orange;
            transition: all ease 1s;
        }
    }

    .elem-date-pagination {
        .swiper-pagination-bullet {
            padding: 10px 22px;
            display: inline-block;
            border: 1px solid #000000;
            border-radius: 100px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
            transition: all ease 0.2s;
            background: transparent;
            display: inline-table;
            opacity: 1;
            &:hover,
            &.swiper-pagination-bullet-active {
                color: $orange;
                border: 1px solid $orange;
                transition: all ease 0.2s;
            }
        }
    }
    .block-swiper-buttons {
        height: 48px;
        @include media-breakpoint-down(lg) {
            margin: 15px auto;
            display: table;
        }
        .swiper-buttons {
            float: left;
            position: relative;
            right: inherit;
            top: inherit;
            height: 50px;
            @include media-breakpoint-down(lg) {
                margin: 20px 0;
            }
        }
    }
    .swiper-pagination {
        .swiper-pagination-progressbar-fill {
            color: red;
            background-color: red;
            display: list-item;
            height: 5px;
        }
    }
    .swiper-slide-active {
        h3 {
            b {
                color: $orange;
            }
        }
    }
    .item {
        border-radius: 15px;
        .bg {
            width: 100%;
            height: 310px;
            display: block;
            background-position: center;
            background-size: cover;
            border-radius: 15px;
        }
        .detail {
            h3 {
                font-family: "Gilroy-Bold";
                font-size: 36px;
                line-height: 40px;
                color: $green;
            }
            .text {
                color: #666680;
            }
        }
    }
}

#block-exp-rea {
    overflow: hidden;
    position: relative;
    ul.cat {
        margin-top: 30px;
        margin-bottom: 70px;
        li {
            display: initial;
            a {
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 25px;
                align-items: center;
                text-align: center;
                color: #252525;
                transition: all ease 0.2s;
                padding: 18px 25px;
                border: 2px solid #000000;
                border-radius: 15px;
                display: inline-block;
                cursor: pointer;
                margin: 10px 0;

                &:hover,
                &.active {
                    color: $orange;
                    border: 2px solid $orange;
                    transition: all ease 0.2s;
                }
            }
        }
    }
    .swiper-exp-rea {
        overflow: hidden;
        position: relative;
        border-radius: 15px;
        .item {
            margin: 0;
            border-radius: 15px;
            .bg {
                width: 100%;
                height: 640px;
                display: block;
                background-position: center;
                background-size: cover;
                border-radius: 15px;
                margin-bottom: 60px;
                @include media-breakpoint-down(md) {
                    height: 380px;
                }
            }
            .legend {
                position: absolute;
                bottom: 0;
            }
        }
        .swiper-buttons {
            position: relative;
            top: initial;
            background: white;
            z-index: 2;
            margin-top: -40px;
            div {
                &.swiper-button-disabled {
                    opacity: 1;
                }
            }
        }
    }
}

#block-temoignages {
    background-color: $green;
    overflow: hidden;
    position: relative;
    color: white;
    .swiper-temoignage {
        overflow: hidden;
        .item {
            color: white;
            .temoignage {
                font-family: "Gilroy-SemiBold";
                font-size: 32px;
                line-height: 44px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #ffffff;
                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                    line-height: 34px;
                }
            }
            .detail {
                padding: 30px;
                width: fit-content;
                margin: auto;
                @include media-breakpoint-down(sm) {
                    padding: 0px;
                }
                .block-img {
                    width: 56px;
                    height: 56px;
                    background: #ea9010;
                    border-radius: 200px;
                    float: left;
                    margin-right: 12px;
                    img {
                        max-height: 30px;
                        margin: auto;
                        display: block;
                    }
                }
                .name {
                    font-family: "Gilroy-SemiBold";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    color: #ffffff;
                    @include media-breakpoint-down(sm) {
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .role {
                    font-family: "Gilroy-SemiBold";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    color: #ffffff;
                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
        .swiper-buttons {
            width: 100px;
            height: 42px;
            position: relative;
            margin: 20px auto 0px auto;
            float: inherit;
            div {
                box-shadow: none;
                background-color: #fff;
                &:hover {
                    margin: 0;
                }
            }
        }
    }
}

#block-philo {
    background-color: $white-alt;
    .elm {
        position: relative;
        .item {
            position: relative;
            &.even {
                margin-top: 30px;
                @include media-breakpoint-down(lg) {
                    margin-top: 0px;
                }
            }
            &.odd {
                margin-top: -30px;
                @include media-breakpoint-down(lg) {
                    margin-top: 0px;
                }
            }
            .elem {
                background: #ffffff;
                border-radius: 20px;
                padding: 30px;
                height: 320px;
                @include media-breakpoint-down(lg) {
                    height: initial;
                }
                h2 {
                    font-family: "Gilroy-SemiBold";
                    font-size: 24px;
                    line-height: 32px;
                }
                .text {
                    font-family: "Gilroy-Medium";
                    font-size: 18px;
                    line-height: 28px;
                    color: #666680;
                }
                &:hover {
                    background: $green;
                    color: white;
                    h2 {
                        color: white;
                    }
                }
            }
        }
    }
}

#block-steps {
    background-color: $white-alt;
    position: relative;
    .elm {
        position: relative;
        width: calc(50% - 15px);
        display: block;
        float: left;
        box-sizing: border-box;
        @include media-breakpoint-down(lg) {
            width: calc(100%);
        }
        &.even {
            float: right;
            @include media-breakpoint-down(lg) {
                margin-top: 0px;
            }
        }
        .item {
            position: relative;

            .elem {
                background: #ebf2fa;
                border-radius: 20px;
                padding: 30px;
                transition: all ease 0.3s;

                @include media-breakpoint-down(lg) {
                    height: initial;
                }
                .number {
                    width: 90.92px;
                    height: 90.92px;
                    background: #134c41;
                    border-radius: 11.4794px;
                    span {
                        font-family: "Gilroy-Bold";
                        font-size: 48px;
                        line-height: 54px;
                        text-align: center;
                        color: $orange;
                    }
                }
                h2 {
                    font-family: "Gilroy-SemiBold";
                    font-size: 24px;
                    line-height: 32px;
                }
                .text {
                    font-family: "Gilroy-Medium";
                    font-size: 18px;
                    line-height: 28px;
                    color: #666680;
                }
                &:hover {
                    background: #ffffff;
                    box-shadow: 0px 3px 22px #eeeeee;
                    transition: all ease 0.3s;
                }
            }
        }
    }
}

#block-filiale {
    .tabs {
        position: relative;
        overflow: hidden;
        .tab-links {
            display: table;
            text-align: center;
            li {
                margin: 15px 6px;
                min-width: 360px;
                display: inline-block;
                @include media-breakpoint-down(lg) {
                    min-width: 100%;
                    margin: 0 0 10px 0;
                }
                a {
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 25px;
                    color: #252525;
                    padding: 12px 15px;
                    border: 1px solid #000000;
                    border-radius: 15px;
                    text-align: center;
                    transition: all ease 0.3s;
                    display: block;
                }
                &:hover,
                &.active {
                    a {
                        color: $orange;
                        border-color: $orange;
                        transition: all ease 0.3s;
                    }
                }
            }
        }
    }
}

.videoyt {
    width: 100%;

    .plyr {
        .item-poster {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            opacity: 1;
            transition: all ease 0.4s;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .plyr__control--overlaid {
        background-image: linear-gradient(90deg, $green 0%, $green 49%, $green 100%);
        padding: 30px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        svg {
            color: $green !important;
            height: 18px;
            width: 18px;
        }
    }

    .plyr__control--overlaid:hover {
        background-image: linear-gradient(90deg, $green 0%, $green 49%, $green 100%);

        svg {
            color: white !important;
        }
    }

    .plyr--video {
        background: transparent !important;
    }

    .plyr__video-wrapper {
        background: transparent !important;
    }

    .plyr--full-ui input[type="range"] {
        color: $green;
    }

    .plyr__video-embed iframe {
        top: -50%;
        height: 200%;
        width: 101%;
    }

    .plyr__poster {
        background-size: cover;
    }

    .plyr__control--overlaid {
        background: white;
    }

    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded="true"] {
        background: $green;
    }

    .plyr__control.plyr__tab-focus {
        box-shadow: 0 0 0 5px rgba($green, 0.5);
    }

    .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
        background: $green;
    }
}

#block-text-media .swiper-media .item .bg {
    border-radius: 15px !important;
}
