body {
    font-family: "Gilroy-Medium";
    font-size: 18px;
    line-height: 28px;
    color: #666680;
    &.menu-active {
        overflow: hidden;
    }
}

/*
|
| Header
|---------
*/
%link-menu {
    text-transform: uppercase;
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
}

#header {
    top: 0;
    width: 100%;
    z-index: 9;
    padding: 15px 0;
    display: block;
    position: fixed;
    transition: top 0.6s;
    box-shadow: 0px 12px 56px rgb(6 28 61 / 10%);
    background: white;
    .header-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-logo {
            img {
                height: 85px;
            }
        }
        .item-nav {
            display: none;

            .item-menu {
                display: flex;

                li {
                    font-family: "Gilroy-Medium";
                    font-size: 20px;
                    line-height: 24px;
                    color: $green;
                    position: relative;
                    &:after {
                        content: "";
                        height: 2px;
                        width: 0;
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        transition: all ease 0.5s;
                        background-color: $orange;
                    }
                    &:hover,
                    &.current-menu-item {
                        &:after {
                            width: 100%;
                            transition: all ease 0.5s;
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                    }

                    &.acces-site-desk {
                        cursor: pointer;
                    }
                    &.acces-site {
                        &:before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            float: right;
                            margin: 7px 0px 0 6px;
                            background-size: cover;
                            background-image: url(../img/icon/arr-alt.svg);
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;

                .item-menu {
                    margin-right: 25px;
                }
            }
            @include media-breakpoint-down(xl) {
                .item-menu {
                    margin-right: 275px;
                    li {
                        font-size: 17px;
                        line-height: 24px;
                        &:not(:last-child) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }

    .lang-container {
        float: right;
        color: #134c41;
        position: absolute;
        right: 190px;
        top: 36px;
        text-transform: uppercase;
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 25px;
        cursor: pointer;
        @include media-breakpoint-down(xl) {
            right: 230px;
        }
        &:after {
            content: "";
            height: 60px;
            width: 60px;
            display: block;
            position: absolute;
            top: -14px;
            left: -10px;
        }
        span {
            cursor: pointer;
            position: relative;
            &:before {
                content: "";
                height: 8px;
                width: 14px;
                display: block;
                top: 3px;
                right: -19px;
                position: absolute;
                background-size: cover;
                transform: rotate(0);
                transition: all ease 0.3s;
                background-image: url(../img/icon/arr_lang.svg);
            }
        }
        &:hover {
            span {
                &:before {
                    content: "";
                    transform: rotate(180deg);
                    transition: all ease 0.3s;
                }
            }
        }
        ul {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            text-transform: uppercase;
            transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
        }

        &:hover {
            ul {
                opacity: 1 !important;
                visibility: visible !important;
                transform: translateX(0) !important;
            }
        }
    }
    .btn {
        @include media-breakpoint-down(xl) {
            position: absolute;
            right: 45px;
            top: 20px;
        }
    }
    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & > .item-burger {
            display: block;
            width: 25px;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $very-dark-grey;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 6px 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    display: flex;
    width: 100%;
    height: 100vh;

    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;

    padding: var(--header-height) var(--side-margin);

    align-items: center;

    background-color: $white;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    ul {
        margin: auto;
        text-align: center;

        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            a {
                text-transform: uppercase;
                font-size: 1.875rem;
                font-weight: 500;
                color: $very-dark-grey;

                @include media-breakpoint-down(xs) {
                    font-size: 26px;
                }
            }
        }
    }
}

#block-nossites {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    bottom: 0;
    color: $green;
    background-color: white;
    z-index: 11;
    display: block;
    transition: all ease 1s;
    overflow: hidden;
    &.active {
        width: 50%;
        transition: all ease 1s;
        @include media-breakpoint-down(xxl) {
            width: 60%;
        }
        @include media-breakpoint-down(xl) {
            width: 60%;
        }
        @include media-breakpoint-down(lg) {
            width: 60%;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        .zone-nossites {
            opacity: 1;
            transition: all ease 1s;
        }
    }
    .zone-nossites {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: fit-content;
        display: flex;
        vertical-align: middle;
        margin: auto;
        padding: 15px 70px;
        opacity: 0;
        transition: all ease 0.6s;
        @include media-breakpoint-down(md) {
            padding: 15px;
        }
        .item-nossites {
            .title-h2 {
                font-family: "Gilroy-Medium";
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                color: $orange;
            }

            .list-links {
                font-family: "Gilroy-Medium";
                font-size: 36px;
                line-height: 40px;
                color: $green;
                @include media-breakpoint-down(md) {
                    font-size: 24px;
                    line-height: 30px;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 20px;
                    line-height: 24px;
                }
                li {
                    margin-bottom: 20px;
                    white-space: nowrap;
                    transition: all ease 0.3s;
                    &:hover {
                        opacity: 0.6;
                        transition: all ease 0.3s;
                    }
                }
            }

            .email {
                font-family: "Gilroy-Medium";
                font-size: 22px;
                line-height: 26px;
                color: $green;
            }

            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }

    .closebtn {
        height: 32px;
        width: 32px;
        position: absolute;
        left: -16px;
        top: calc(50% - 16px);
        cursor: pointer;
        display: none;
        top: 40px;
        right: 80px;
        left: initial;
        @include media-breakpoint-down(sm) {
            right: 40px;
        }

        &:before {
            content: "";
            height: 65px;
            width: 65px;
            position: absolute;
            top: -17px;
            left: -15px;
            border-radius: 10%;
            background-color: #f5f6f7;
        }

        .bar {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $green;
            border-radius: 2px;
            transform: translate(0px, 8px) rotate(45deg);
            position: absolute;
            top: 7px;
        }

        .bar2 {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $green;
            border-radius: 2px;
            transform: translate(0px, -8px) rotate(-45deg);
            position: absolute;
            left: 0;
            top: 23px;
        }
    }
}
#slider-left {
    &:before {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        z-index: 0;
        background: transparent;
        transition: all ease 0.6s;
    }
    &.active {
        &:before {
            content: "";
            right: 0;
            opacity: 0.6;
            z-index: 11;
            background: black;
            transition: all ease 0.6s;
        }
    }
}

.offset-menu {
    padding-top: 124px;
}

.acces-site-mobile {
    display: none;
    cursor: pointer;
    @include media-breakpoint-down(xl) {
        font-family: "Gilroy-medium";
        left: -40px;
        top: 250px;
        position: fixed;
        transform: rotate(-90deg);
        color: white;
        background-color: $green;
        border: 1px solid white;
        display: block;
        z-index: 9;
        padding: 3px 14px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 2px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: all ease 0.3s;
        box-shadow: 0px 0px 10px rgb(6 28 61 / 30%);
        &:hover {
            background-color: $orange;
            transition: all ease 0.3s;
        }
    }
}
