/*
|--------------------
|       Contact
|--------------------
*/

.page-template-contact {
    background-color: $green;
    #page-contact {
        color: white;
        margin-top: 150px;
        @include media-breakpoint-down(lg) {
            margin-top: 45px;
        }
        .header-contact {
            padding-bottom: 180px;
            @include media-breakpoint-down(lg) {
                padding-bottom: 20px;
            }
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
            h1 {
                color: white;
            }
            .desc {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
                opacity: 0.7;
            }
            .adr-image {
                width: 90px;
                height: 90px;
                background: #ffffff;
                border-radius: 152px;
                background-position: center;
                background-size: cover;
                float: left;
                margin-right: 15px;
                @include media-breakpoint-down(sm) {
                    float: initial;
                    margin: 0 auto 30px auto;
                }
            }
            .adr-title {
                font-family: "Gilroy-Regular";
                font-size: 20px;
                line-height: 100%;
                letter-spacing: 0.01em;
                color: #ffffff;
                opacity: 0.6;
            }
            .adr-text {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                color: #ffffff;
            }
        }

        .block-form {
            position: relative;
            .contact-form {
                z-index: 2;
                position: absolute;
                background: white;
                border-radius: 24px;
                box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.1);
                @include media-breakpoint-down(lg) {
                    position: relative;
                    margin-top: 0px !important;
                }
                #tabs-nav {
                    list-style: none;
                    overflow: auto;
                    display: inline-block;
                    margin: 50px 50px 0px 50px;
                    @include media-breakpoint-down(xl) {
                        margin: 20px 20px 0px 20px;
                    }
                    li {
                        font-family: "Gilroy-medium";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 32px;
                        float: left;
                        cursor: pointer;
                        margin: 0 50px 0 0;
                        color: #b8c9c6;
                        transition: all ease 0.3s;
                        &:hover,
                        &.active {
                            color: $green;
                            transition: all ease 0.3s;
                            border-bottom: 3px solid $green;
                        }
                        a {
                            font-family: "Gilroy-bold";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 21px;
                        }
                    }
                }
                #tabs-content {
                    .tab-content {
                        transition: all ease 0.3s;
                    }
                }
            }
        }

        .gfield_description {
            display: none;
        }
        .gform_confirmation_message {
            font-family: "Gilroy-medium";
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.02em;
            color: #0f1214;
            min-height: 600px;
            font-size: 18px;
            line-height: 20px;
            padding: 20px 15px 0 15px;
            text-align: center;
        }
        .gform_wrapper.gravity-theme {
            h3 {
                font-family: "Gilroy-medium";
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 30px;
                letter-spacing: -0.02em;
                color: #0f1214;
                margin: 20px 0;
            }
            .validation_message {
                font-family: inherit;
                text-transform: inherit;
            }
            .validation_message {
                padding: 3px 10px;
            }
            .gform_ajax_spinner {
                position: absolute;
                margin-top: 5px;
            }
            .gform_submission_error {
                text-transform: initial;
                font-size: 15px;
                font-weight: 700;
                &:after {
                    content: none;
                }
                span {
                    display: none;
                }
            }
            .gform_validation_errors {
                margin: 50px 50px 0px 50px;
                position: relative;
                width: calc(100% - 60px);
                padding: 10px;
                box-shadow: none;
                border: 1px solid $green;
                border-radius: initial;
                display: none;
            }
            .gform_heading {
                display: none;
            }
            .gform-icon {
                display: none !important;
            }
            .gform_body {
                h2 {
                    @include media-breakpoint-down(xl) {
                        font-size: 38px;
                        line-height: 43px;
                    }
                    &:after {
                        content: none;
                    }
                }

                .gform_required_legend {
                    display: none;
                }
                .gform_title {
                    display: none;
                }
                .gfield {
                    margin-bottom: 10px;
                    font-family: "Gilroy-medium";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 22px;
                    color: #061c3d;
                }
                .gfield_label {
                    font-family: "Gilroy-medium";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 22px;
                    color: #1c1c1e;
                }
                .ginput_container_date {
                    .datepicker {
                        width: 100%;
                    }
                }

                .gfield_date_dropdown_day,
                .gfield_date_dropdown_month,
                .gfield_date_dropdown_year {
                    max-width: 33.33%;
                }
                input[type="text"],
                input[type="email"],
                select,
                textarea {
                    border: 1px solid #e6e8ec;
                    border-radius: 5px;
                    font-size: 16px !important;
                    line-height: initial;
                    padding: 15px 15px !important;
                }
                .ginput_container_phone {
                    input {
                        border: 1px solid #e6e8ec;
                        border-radius: 5px;
                        font-size: 16px !important;
                        line-height: initial;
                        padding-top: 15px !important;
                        padding-bottom: 15px !important;
                    }
                }
                textarea {
                    height: 120px !important;
                }
                select {
                    position: relative;
                    background-image: url("../img/icon/arr-mini.svg");
                    background-repeat: no-repeat;
                    background-size: 8px;
                    background-position: calc(100% - 20px) center;
                }
                .ginput_container {
                    .gfield_consent_label {
                        font-family: "Gilroy-medium";
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 20px;
                        color: #0f1214;
                        margin-left: 28px;
                        a {
                            text-decoration: underline;
                        }
                    }
                }
                .ginput_container_consent {
                    position: relative;
                    input[type="checkbox"] {
                        position: absolute;
                        cursor: pointer;
                        border: none;
                        left: 0;
                        top: 0px;
                        padding: 0 !important;
                        margin: 0;
                    }

                    input[type="checkbox"]:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 21px;
                        height: 21px;
                        top: 0;
                        left: 0;
                        border: 1px solid #1c1c1e;
                        border-radius: 5px;
                        background-color: transparent;
                    }

                    input[type="checkbox"]:checked:after {
                        content: "";
                        display: block;
                        width: 21px;
                        height: 21px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        border-radius: 5px;
                        background: $green;
                        border: 1px solid $green;
                        background-image: url("../img/icon/valid.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 15px;
                    }
                }
                .gform_fields {
                    padding: 10px 50px 20px 50px;
                    @include media-breakpoint-down(xl) {
                        padding: 10px 20px 20px 20px;
                    }
                }
            }
            .gform_footer {
                padding: 0 !important;
                margin: 0 50px 40px 50px !important;
                position: relative;
                display: inline-block !important;
                @include media-breakpoint-down(xl) {
                    margin: 0 20px 20px 20px !important;
                }
                input[type="submit"] {
                    font-family: "Gilroy-SemiBold";
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 15px;
                    border: 1px solid $green;
                    background-color: $green;
                    display: inline-block;
                    position: relative;
                    padding: 17px 35px 16px 35px;
                    transition: all ease 0.3s;
                    &:hover {
                        border: 1px solid $orange;
                        background-color: $orange;
                        transition: all ease 0.3s;
                        cursor: pointer;
                    }
                }
            }
        }
        .gform_fileupload_rules {
            display: none;
        }
        .ginput_container_fileupload {
            height: 100px;
            border: 1px dashed $green;
            position: relative;
            text-align: center;
            transition: all ease 0.3s;
            &:hover {
                background: rgb(244, 248, 245);
                transition: all ease 0.3s;
            }
            &:before {
                content: attr(data-content);
                background-image: url("../img/global/add-file.png");
                font-family: "Neue Haas Grotesk Display Pro";
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.14em;
                color: rgb(133, 139, 144);
                position: absolute;
                inset: 0;
                vertical-align: middle;
                align-items: center;
                display: grid;
                width: 30px;
                height: 30px;
                background-size: cover;
                padding: 0;
                margin: auto;
                opacity: 0.4;
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
            }
            &.active {
                &:before {
                    background: none;
                    width: initial;
                    height: initial;
                    opacity: 1;
                }
            }
        }
        input[type="file"] {
            height: 100px;
            opacity: 0;
            cursor: pointer;
        }

        .bg-img-contact {
            position: relative;
            height: 640px;
            width: 100%;
            background-position: center;
            background-size: cover;
            @include media-breakpoint-down(lg) {
                margin-top: -560px;
            }
        }
        #wavesvg {
            height: 100px;
            width: 100%;
            position: absolute;
            top: -1px;
            z-index: 1;
            fill: transparent;
            path {
                stroke: none;
                fill: #134c41;
            }
        }
    }
}
