/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: $font-family-default;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-color;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all ease 0.4;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}
h1,
.title-h1,
h2 {
    font-family: "Gilroy-Bold";
    font-size: 44px;
    line-height: 56px;
    color: $green;
    @include media-breakpoint-down(xl) {
        font-size: 40px;
        line-height: 46px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 40px;
    }
}

button,
input,
select,
textarea {
    border: 0;
    border-radius: 0;

    background-color: transparent;

    appearance: none;

    letter-spacing: inherit;
    font-family: inherit;
    font-size: inherit;
}

a,
button,
select,
input,
textarea {
    color: inherit;

    &:focus,
    &:focus-visible {
        outline: none;
        outline-offset: 0;
    }
}

button {
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img {
    max-width: inherit;
}
a {
    text-decoration: none;
}
sup {
    top: -0.5em;
    font-size: 29px;
    font-weight: bold;
}
.rel {
    position: relative;
}
.z1 {
    z-index: 1;
}
.m-a {
    margin: auto;
    display: block;
}
.h100 {
    height: 100%;
}
img,
button {
    display: inline-block;
}

.ml-neg {
    margin-right: -20px;
}
.mr-neg {
    margin-left: -20px;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}
.card-img-container {
    overflow: hidden;
    &:hover {
        .card-img {
            transform: scale(1.05);
        }
    }
    .card-img {
        transition: all ease 0.6s;
    }
}
.clearfix {
    clear: both;
    width: 100%;
    display: block;
}

.d-t {
    display: table !important;
}

.td-0 {
    text-decoration: none;
}

.txt-c {
    text-align: center !important;
}

@include media-breakpoint-down(sm) {
    .txt-c-sm {
        text-align: center !important;
    }
}

.txt-l {
    text-align: left !important;
}

.txt-r {
    text-align: right !important;
    @include media-breakpoint-down(md) {
        text-align: center !important;
    }
}

.w100 {
    width: 100%;
}
.jc-c {
    justify-content: center;
}

.f-l {
    float: left;
}

.f-r {
    float: right;
}

.disp-desk {
    display: block !important;
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.disp-mob {
    display: none !important;
    @include media-breakpoint-down(md) {
        display: block !important;
    }
}

.disp-desk-lg {
    display: block !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}

.disp-mob-lg {
    display: none !important;
    @include media-breakpoint-down(lg) {
        display: block !important;
    }
}

.disp-desk-flex-lg {
    display: flex !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}

.disp-mob-flex-lg {
    display: none !important;
    @include media-breakpoint-down(lg) {
        display: flex !important;
    }
}
.dflex {
    display: flex !important;
    vertical-align: middle;
    align-items: center;
}
.dflex-home {
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.bg-green {
    background-color: $green;
}
.bg-yellow {
    background-color: $orange;
}
.c-white {
    color: $white;
}
.btn {
    font-family: "Gilroy-SemiBold";
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    border-radius: 15px;
    border: 1px solid $green;
    background-color: $green;
    display: inline-block;
    position: relative;
    padding: 17px 35px 16px 35px;
    transition: all ease 0.3s;
    &:hover {
        border: 1px solid $orange;
        background-color: $orange;
        transition: all ease 0.3s;
    }
    &.btn-alt {
        color: $green;
        border: 1px solid $white;
        background-color: $white;
        &:hover {
            color: $white;
            border: 1px solid $white;
            background-color: transparent;
            transition: all ease 0.3s;
        }
    }
}

.videoyt {
    width: 100%;

    .plyr {
        .item-poster {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            opacity: 1;
            transition: all ease 0.4s;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .plyr__control--overlaid {
        background-image: linear-gradient(90deg, $green 0%, $green 49%, $green 100%);
        padding: 30px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        svg {
            color: $green !important;
            height: 18px;
            width: 18px;
        }
    }

    .plyr__control--overlaid:hover {
        background-image: linear-gradient(90deg, $green 0%, $green 49%, $green 100%);

        svg {
            color: white !important;
        }
    }

    .plyr--video {
        border-radius: 20px;
        background: transparent !important;
    }

    .plyr__video-wrapper {
        background: transparent !important;
    }

    .plyr--full-ui input[type="range"] {
        color: $green;
    }

    .plyr__video-embed iframe {
        top: -50%;
        height: 200%;
        width: 101%;
    }

    .plyr__poster {
        background-size: cover;
    }

    .plyr__control--overlaid {
        background: white;
    }

    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded="true"] {
        background: $green;
    }

    .plyr__control.plyr__tab-focus {
        box-shadow: 0 0 0 5px rgba($green, 0.5);
    }

    .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
        background: $green;
    }
}

.swiper-buttons {
    float: right;
    width: 100px;
    height: 42px;
    position: absolute;
    right: 0;
    top: 21px;
    @include media-breakpoint-down(lg) {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
    }
    .button-prev,
    .button-prev-exp {
        float: left;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .button-next,
    .button-next-exp {
        float: right;
        transform: rotate(180deg);
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    }
    .button-prev,
    .button-prev-exp,
    .button-next,
    .button-next-exp {
        height: 42px;
        width: 42px;
        cursor: pointer;
        display: block;
        transition: all ease 0.2s;
        border-radius: 50px;
        position: relative;
        background-color: #f7d39f;
        &:before {
            content: "";
            display: block;
            height: 17px;
            width: 20px;
            top: 13px;
            left: 11px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url("../img/icon/arr-green.svg");
        }
        &.swiper-button-disabled {
            opacity: 0.3;
        }
        &:hover {
            box-shadow: none;
            transition: all ease 0.2s;
            margin: 4px 0 0 0;
        }
    }
}
.iziModal {
    border-radius: 12px !important;
    max-width: 50% !important;
    @include media-breakpoint-down(md) {
        max-width: 50% !important;
    }
    @include media-breakpoint-down(sm) {
        max-width: 90% !important;
    }
    .icon-close {
        transform: rotate(45deg);
        color: $green;
        line-height: 35px;
        font-size: 50px;
        position: fixed;
        top: 4px;
        right: 7px;
        z-index: 1;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            display: none;
        }
    }
    .modal-body {
        .details {
            padding: 15px;
        }
    }
    .iziModal-content {
        border-radius: 12px;
        padding: 30px !important;
        h2 {
            font-family: "Gilroy-Bold";
            font-size: 48px;
            line-height: 48px;
            text-align: center;
            color: #134c41;
            hyphens: auto;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
                line-height: 40px;
            }
            @include media-breakpoint-down(md) {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
}
